import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Accordion, Button, Form, Modal, Toast, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faChevronRight, faChevronDown, faCheckCircle } from '@fortawesome/free-solid-svg-icons';

import md from '../../markdown';
import { ApiContext } from '../../context/Api';

const USER_INFO = 'userInfo';
const USER_ANSWERS = 'userAnswers';

export default function Question({ block, questionCounter, lessonId, answers }) {
  const { apiService } = useContext(ApiContext);
  const user = JSON.parse(localStorage.getItem(USER_INFO));
  const [answer, setAnswer] = useState('');
  const [answerId, setAnswerId] = useState();
  const [modalIsOpen, setIsOpen] = useState(false);
  const [isExpanded, setIsExpanded] = useState(true);
  const [showAnswerToast, setShowAnswerToast] = useState(false);
  const [showAskHelpToast, setShowAskHelpToast] = useState(false);
  const [showAnswerField, setShowAnswerField] = useState(true);

  const handleClose = () => setIsOpen(false);
  const handleShow = () => setIsOpen(true);

  let content = block.content || '';
  const { t } = useTranslation();

  const _saveAnswerLocal = useCallback(
    async answer => {
      const lessonBlockId = block.id;
      const userAnswer = {
        lessonId: lessonId,
        lessonblock: lessonBlockId,
        answer: answer
      };

      console.log('saved answers');
      const userAnswers = JSON.parse(localStorage.getItem(USER_ANSWERS));

      if (!userAnswers) {
        localStorage.setItem(USER_ANSWERS, JSON.stringify([userAnswer]));
      } else {
        const exists = userAnswers.find(e => e.lessonblock === lessonBlockId);
        if (exists) {
          exists.answer = answer;
        } else {
          userAnswers.push(userAnswer);
        }
        localStorage.setItem(USER_ANSWERS, JSON.stringify(userAnswers));
      }
    },
    [block.id, lessonId]
  );

  const _getUserAnswerLocal = useCallback(async () => {
    const userAnswers = JSON.parse(localStorage.getItem(USER_ANSWERS));
    if (!userAnswers) {
      return;
    }
    const userAnswer = userAnswers.find(e => e.lessonblock === block.id);
    if (userAnswer) {
      setAnswer(userAnswer.answer);
      setShowAnswerField(false);
    }
  }, [block.id]);

  const getUserAnswer = useCallback(
    async function() {
      await _getUserAnswerLocal();

      // get answer from database (the answers property passed to this component)
      const user = JSON.parse(localStorage.getItem(USER_INFO));
      if (user && user.user.id && answers) {
        const userAnswer = answers.find(
          a =>
            (!a.user || a.user.id.toString() === user.user.id.toString()) &&
            (a.lessonblock.id === block.id || (a.lessonQuestionId && a.lessonQuestionId.toString() === block.id))
        );
        if (userAnswer) {
          setAnswer(userAnswer.answer);
          setAnswerId(userAnswer.id);
          setShowAnswerField(false);
        }
      }
    },
    [_getUserAnswerLocal, answers, block.id]
  );

  useEffect(() => {
    getUserAnswer();
  }, [getUserAnswer, answers]);

  useEffect(() => {
    // when answerId changes, save it to local
    if (answerId) {
      _saveAnswerLocal(answer);
    }
  }, [_saveAnswerLocal, answerId, answer]);

  // Underscores are interpretted as markdown,
  // so escape any used as blanks (more than 3 in a row)
  const regex = /([_]{3,})/gm;
  content = content.replace(regex, match => {
    return '\\_'.repeat(match.length);
  });

  async function saveAnswer(event) {
    event.preventDefault();
    const answer = event.target.answer.value;
    await _saveAnswerLocal(answer);
    await _saveAnswerDatabase(answer);
    setAnswer(answer);
    setShowAnswerToast(true);
    setShowAnswerField(false);
  }

  async function _saveAnswerDatabase(answer) {
    // If user is not logged in, don't save to db
    if (!user) {
      return;
    }

    try {
      const result = await apiService.saveUserAnswer(user, lessonId, block.id, answerId, answer);
      if (result && result.data && (result.data.createAnswer || result.data.updateAnswer)) {
        let answerId = '';
        if (result.data.createAnswer) {
          answerId = result.data.createAnswer.data.id;
        }
        if (result.data.updateAnswer) {
          answerId = result.data.updateAnswer.data.id;
        }
        setAnswerId(answerId);
      }
    } catch (error) {
      console.error(error);
    }
  }

  async function askForHelp(event) {
    event.preventDefault();
    const email = event.target.email.value;
    const explanation = event.target.explanation.value;
    const courseLessonQuestion = block.name;

    apiService
      .sendEmail(process.env.REACT_APP_CONTACT_TO.split(','), 'Question Confusing', null, {
        replyTo: email,
        text: `
        Question Id: ${courseLessonQuestion} \n
        Question: ${block.content} \n
        Confusion: ${explanation}
          `
      })
      .then(response => {
        // Handle success.
        setShowAskHelpToast(true);
      })
      .catch(error => {
        console.log('An error occurred:', error.response);
      });

    handleClose();
  }

  function handleAccordion() {
    setIsExpanded(!isExpanded);
  }

  return (
    <div className="lesson-block-question mb-4" key={block.id}>
      <FontAwesomeIcon icon={faQuestionCircle} />
      <ol start={questionCounter}>
        <li
          key={block.id}
          data-name={block.name}
          data-id={block.id}
          dangerouslySetInnerHTML={{
            __html: md.makeHtml(content)
          }}
        />
      </ol>
      <Accordion defaultActiveKey="0">
        <Accordion.Toggle as={Button} variant="link" eventKey="0" onClick={handleAccordion}>
          <FontAwesomeIcon icon={isExpanded ? faChevronDown : faChevronRight} />
          <span className="mis-1">{t('lesson.question.yourAnswer')}</span>
        </Accordion.Toggle>
        <Accordion.Collapse eventKey="0">
          <Form onSubmit={saveAnswer}>
            <Form.Group className="ml-3">
              {showAnswerField && (
                <Form.Control
                  name="answer"
                  as="textarea"
                  rows={3}
                  placeholder={t('lesson.question.enterYourAnswer')}
                  defaultValue={answer}
                />
              )}
              {!showAnswerField && answer && <p className="answer-background">{answer}</p>}
            </Form.Group>

            <Row className="ml-3">
              {showAnswerField ? (
                <div>
                  <Button variant="primary" type="submit">
                    {t('core.save')}
                  </Button>
                  <Button variant="secondary" onClick={() => setShowAnswerField(false)} className="mis-3">
                    {t('core.cancel')}
                  </Button>
                </div>
              ) : (
                <Button variant="secondary" onClick={() => setShowAnswerField(true)}>
                  {answer ? t('lesson.question.updateAnswer') : t('lesson.question.addAnswer')}
                </Button>
              )}
              <Button variant="link" onClick={handleShow}>
                {t('lesson.question.needHelp')}
              </Button>
            </Row>
          </Form>
        </Accordion.Collapse>
      </Accordion>
      <Modal show={modalIsOpen} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{t('lesson.question.askForHelp')}</Modal.Title>
        </Modal.Header>
        <Form onSubmit={askForHelp}>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>{t('lesson.question.yourEmailOptional')}</Form.Label>
              <Form.Control type="email" placeholder="name@example.com" name="email" autoFocus />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>{t('lesson.question.explainWhatIsConfusing')}</Form.Label>
              <Form.Control as="textarea" rows={3} name="explanation" />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              {t('core.close')}
            </Button>
            <Button variant="primary" type="submit" className="mis-3">
              {t('lesson.question.askForHelp')}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
      <Toast
        onClose={() => setShowAnswerToast(false)}
        show={showAnswerToast}
        delay={3000}
        className="bg-success text-white"
        autohide={true}
      >
        <Toast.Header>
          <FontAwesomeIcon size="2x" icon={faCheckCircle} className="text-success toast-header-icon" />
          <strong className="mr-auto">{t('lesson.question.answer')}</strong>
        </Toast.Header>
        <Toast.Body>{t('lesson.question.answerSaved')}</Toast.Body>
      </Toast>
      <Toast
        onClose={() => setShowAskHelpToast(false)}
        show={showAskHelpToast}
        delay={3000}
        className="bg-success text-white"
        autohide={true}
      >
        <Toast.Header>
          <FontAwesomeIcon size="2x" icon={faCheckCircle} className="text-success toast-header-icon" />
          <strong className="mr-auto">{t('lesson.question.needHelp')}</strong>
        </Toast.Header>
        <Toast.Body>{t('lesson.question.helpEmailSent')}</Toast.Body>
      </Toast>
    </div>
  );
}
