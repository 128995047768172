import React, { useEffect, useMemo, useState } from 'react';
import axios from 'axios';
import { Cookies } from 'react-cookie';
import { set } from 'lodash';

const DEFAULT_QUERY = 'graphql';
const STRAPI_VERSION = 'strapiVersion';

export const ApiContext = React.createContext(null);
const cookies = new Cookies();

export function ApiProvider({ children, i18n }) {
  const [locales, setLocales] = useState([
    {
      code: 'en',
      localName: 'English'
    },
    {
      code: 'ar',
      localName: 'العربية'
    }
  ]);
  const [localesLoaded, setLocalesLoaded] = useState(false);

  const apiService = useMemo(() => new ApiService(), []);

  // onLoad
  useEffect(() => {
    // get locales and translation strings from API
    apiService.getAppTranslations().then(response => {
      // get available
      if (response.data.languages) {
        const apiLocales = response.data.languages.map(lng => ({
          code: lng.i18n_locale.code,
          localName: lng.localName,
          published: !!lng.published_at || !!lng.publishedAt // v3=published_at, v4=publishedAt
        }));
        setLocales(apiLocales);
        setLocalesLoaded(true);
      }

      const resources = {};

      // process each individual translation
      const setTranslation = t => {
        let locale = resources[t.locale];
        if (!locale) {
          locale = resources[t.locale] = { translation: {} };
        }
        set(locale.translation, t.key, t.translation);
      };

      // loop through all translations and each nested localization
      for (const t of response.data.appTranslations) {
        setTranslation(t);

        if (t.localizations) {
          for (const l of t.localizations) {
            setTranslation(l);
          }
        }
      }

      // load all languages
      for (const lng in resources) {
        i18n.addResourceBundle(lng, 'translation', resources[lng].translation, true, true);
      }
    });
  }, [apiService, i18n]);

  const context = {
    locales,
    localesLoaded,
    apiService
  };
  return <ApiContext.Provider value={context}>{children}</ApiContext.Provider>;
}

class ApiService {
  constructor() {
    // Use a singleton so there is only ever one instance
    if (ApiService.instance) {
      return ApiService.instance;
    }

    this.strapiVersion = '1.0.0';
    this.cmsUrl = process.env.REACT_APP_URL || 'http://localhost:1337/';

    this._init();

    ApiService.instance = this;
    return this;
  }

  async _init() {
    // try get Strapi version from cookie
    let version = cookies.get(STRAPI_VERSION);

    // get Strapi version from API response header using HEAD request
    let versionHeader;
    try {
      const response = await axios.head(this.cmsUrl);
      versionHeader = response.headers['x-powered-by'];
    } catch (error) {
      // if any error happens, we should still get the headers
      versionHeader = error.response.headers['x-powered-by'];
    } finally {
      if (versionHeader) {
        // expected header format: "Strapi v1.2.3"
        version = versionHeader.replace('Strapi v', '');
      }
    }

    if (version) {
      this.setStrapiVersion(version);
    }
  }

  /**
   * Get all AppTranslations
   * @returns {Promise<*|null>}
   */
  async getAppTranslations() {
    if (await this.isVersion4()) {
      const responseData = await this._queryGraphql(`
          query {
            appTranslations(pagination: { pageSize: 100 }) {
              data {
                id
                attributes {
                  key
                  translation
                  locale
                  localizations {
                    data {
                      attributes {
                        key
                        translation
                        locale
                      }
                    }
                  }
                }
              }
            }
            languages(publicationState: PREVIEW, pagination: { pageSize: 25 }) {
              data {
                id
                attributes {
                  localName
                  translatedName
                  publishedAt
                  locale
                  code
                }
              }
            }
          }`);
      return {
        data: {
          appTranslations: this.formatStrapi4GraphResponse(responseData.data.appTranslations),
          languages: this.formatStrapi4GraphResponse(responseData.data.languages)
        }
      };
    }
    return this._queryGraphql(`
          query {
            appTranslations {
              key
              translation
              locale
              localizations {
                key
                translation
                locale
              }
            }
            languages(publicationState: PREVIEW) {
              localName
              translatedName
              i18n_locale {
                code
                name
              }
              published_at
            }
          }`);
  }

  /**
   * Get all Courses for a given language
   * @param language
   * @returns {Promise<*|null>}
   */
  async getCourses(language) {
    if (await this.isVersion4()) {
      const responseData = await this._queryGraphql(`
          query Courses {
            courses(locale: "${language}", pagination: { pageSize: 25 }) {
              data {
                id
                attributes {
                  name
                  slug
                  icon {
                    data {
                      attributes {
                        url
                      }
                    }
                  }
                  lessons {
                    data {
                      id
                      attributes {
                        name
                        slug
                        subtitle
                        sortOrder
                      }
                    }
                  }
                }
              }
            }
          }`);
      return {
        data: {
          courses: this.formatStrapi4GraphResponse(responseData.data.courses)
        }
      };
    }
    return this._queryGraphql(`
          query {
            courses(locale: "${language}") {
              id
              name
              slug
              icon {
                url
              }
              lessons {
                sortOrder
                id
                name
                subtitle
                slug
              }
            }
          }`);
  }

  /**
   * Get Course by language and slug
   * @param language
   * @param courseSlug
   * @returns {Promise<null|any>}
   */
  async getCourse(language, courseSlug) {
    if (await this.isVersion4()) {
      const responseData = await this._queryGraphql(`
          query Courses {
            courses(filters: { slug: { eq: "${courseSlug}" } }, locale: "${language}") {
              data {
                id
                attributes {
                  name
                  slug
                  subtitle
                  description
                  lessons {
                    data {
                      id
                      attributes {
                        slug
                      }
                    }
                  }
                }
              }
            }
          }
        `);
      return {
        data: {
          courses: this.formatStrapi4GraphResponse(responseData.data.courses)
        }
      };
    }
    return this._queryGraphql(`
          query {
            courses (locale: "${language}", where: {slug:"${courseSlug}"}) {
              id
              slug
              name
              subtitle
              description
              lessons (sort:"sortOrder", limit: 1) {
                id
                slug
              }
            }
          }
        `);
  }

  /**
   * Get single lesson by ID
   * @param id
   * @returns {Promise<null|any>}
   */
  async getLesson(id) {
    if (await this.isVersion4()) {
      const responseData = await this._queryGraphql(`
          query Lesson {
            lesson(id: "${id}") {
              data {
                id
                attributes {
                  name
                  subtitle
                  slug
                  course {
                    data {
                      id
                      attributes {
                        slug
                      }
                    }
                  }
                  content {
                    ... on ComponentLessonblockActivities {
                      id
                      name
                      content
                      type
                    }
                    ... on ComponentLessonblockApplication {
                      id
                      name
                      content
                      type
                    }
                    ... on ComponentLessonblockObjectives {
                      id
                      name
                      content
                      type
                    }
                    ... on ComponentLessonblockQuestions {
                      id
                      name
                      content
                      type
                    }
                    ... on ComponentLessonblockRecap {
                      id
                      name
                      content
                      type
                    }
                    ... on ComponentLessonblockScripture {
                      id
                      name
                      heading
                      shouldDisplayName
                      content
                      audioUrl
                      type
                    }
                    ... on ComponentLessonblockSharing {
                      id
                      name
                      content
                      type
                    }
                    ... on Error {
                      code
                      message
                    }
                  }
                }
              }
            }
          }
        `);
      return {
        data: {
          lesson: this.formatStrapi4GraphResponse(responseData.data.lesson)
        }
      };
    }
    return this._queryGraphql(`
          query {
            lesson (id: "${id}") {
              course {
                slug
              }
              id
              name
              subtitle
              slug
              blocks (sort:"sortOrder:asc") {
                id
                sortOrder
                type
                name
                shouldDisplayName
                content
                audioUrl
              }
            }
          }
        `);
  }

  /**
   * Get user answers
   * @param user
   * @returns {Promise<null|any>}
   */
  async getUserAnswers(user) {
    if (await this.isVersion4()) {
      const responseData = await this._queryGraphql(
        `
        {
          answers(filters: {user: {id: {eq: "1"}}}) {
            data {
              id
              attributes {
                answer
                lessonQuestionId
                lessonblock {
                  data {
                    id
                  }
                }
              }
            }
          }
        }
      `,
        user.jwt
      );
      return {
        data: {
          userdata: this.formatStrapi4GraphResponse(responseData.data.userdata)
        }
      };
    }
    return this._queryGraphql(
      `
          query {
            answers (where: {user:"${user.user.id}"}) {
              id
              answer
              lessonblock {
                  id
              }
            }
          }
        `,
      user.jwt
    );
  }

  /**
   * Get user progress data
   * @param user
   * @returns {Promise<null|any>}
   */
  async getUserData(user) {
    if (await this.isVersion4()) {
      const responseData = await this._queryGraphql(
        `
        query Userdata {
          userdata(filters: { userId: { eq: "${user.user.id}" } }) {
            data {
              id
              attributes {
                completedLessons
                userId
              }
            }
          }
        }
      `,
        user.jwt
      );
      return {
        data: {
          userdata: this.formatStrapi4GraphResponse(responseData.data.userdata)
        }
      };
    }
    return this._queryGraphql(
      `
          query {
            userdata (where: {userId:"${user.user.id}"}) {
              id,
              completedLessons,
              userId
            }
          }
        `,
      user.jwt
    );
  }

  /**
   * Save user progress data
   * @param user
   * @param userPostId
   * @param completedLessons
   * @returns {Promise<null|any>}
   */
  async saveUserData(user, userPostId, completedLessons) {
    const exists = !!userPostId;
    const mutationName = exists ? 'updateUserdatum' : 'createUserdatum';
    if (await this.isVersion4()) {
      const whereClause = exists ? `id: "${userPostId}",` : '';
      return this._queryGraphql(
        `
        mutation {
          ${mutationName}(
            ${whereClause}
            data: { 
              completedLessons: "${completedLessons.join(',')}",
              userId:"${user.user.id}"
            }
          ) {
            data {
              id
            }
          }
        }
      `,
        user.jwt
      );
    } else {
      const whereClause = exists ? `where: { id: "${userPostId}" }` : '';
      return this._queryGraphql(
        `
        mutation {
            ${mutationName}(
              input: {
                ${whereClause}
                data: { completedLessons: "${completedLessons.join(',')}", userId:"${user.user.id}" }
              }
            ) {
              userdatum {
                completedLessons,
                userId
              }
            }
          }
        `,
        user.jwt
      );
    }
  }

  /**
   * Save/Update a user answer
   * @param user
   * @param lessonId
   * @param blockId
   * @param answerId
   * @param answerContent
   * @returns {Promise<null|any>}
   */
  async saveUserAnswer(user, lessonId, blockId, answerId, answerContent) {
    const exists = answerId !== undefined && answerId !== null;
    const mutationName = exists ? 'updateAnswer' : 'createAnswer';
    if (await this.isVersion4()) {
      const whereClause = exists ? `id: "${answerId}",` : '';
      return this._queryGraphql(
        `
        mutation {
          ${mutationName}(
            ${whereClause}
            data: { 
              answer: "${answerContent}",
              lesson: "${lessonId}",
              lessonblock: "${blockId}",
              lessonQuestionId: ${blockId},
              user: "${user.user.id}"
            }
          ) {
            data {
              id
              attributes {
                answer
              }
            }
          }
        }
        `,
        user.jwt
      );
    } else {
      const whereClause = exists ? `where: {id:"${answerId}"}` : '';

      return this._queryGraphql(
        `
        mutation {
            ${mutationName}(
              input: {
                ${whereClause}
                data: {
                  answer: "${answerContent}",
                  lessonblock: "${blockId}",
                  user: "${user.user.id}"
                }
              }
            ) {
              answer {
                id
                answer
              }
            }
          }
        `,
        user.jwt
      );
    }
  }

  /**
   * Send email via API
   * @param to
   * @param subject
   * @param body
   * @returns {Promise<void>}
   */
  sendEmail(to, subject, body, options) {
    const config = {
      ...options,
      to,
      subject
    };
    if (body) {
      config.html = body;
    }
    return this.sendPostRequest('send-email', config);
  }

  /**
   * Send generic POST request. Prepends `path` with proper CMS URL
   * @param path
   * @param body
   * @returns {Promise<Promise<AxiosResponse<T>>>}
   */
  async sendPostRequest(path, body) {
    const apiPath = (await this.isVersion4()) ? `api/${path}` : path;
    return axios.post(`${this.cmsUrl}${apiPath}`, body);
  }

  /**
   * Update this.strapiVersion and associated cookie value
   * @param version
   */
  setStrapiVersion(version) {
    this.strapiVersion = version;
    cookies.set(STRAPI_VERSION, this.strapiVersion, {
      maxAge: 604800 // 7 days (in sec)
    });
  }

  /**
   * Execute a GraphQL query
   * @param query
   * @returns {Promise<null|any>}
   * @private
   */
  async _queryGraphql(query, auth) {
    const headers = { 'Content-Type': 'application/json' };
    if (auth) {
      headers['Authorization'] = `Bearer ${auth}`;
    }

    let response;
    try {
      response = await axios.post(this.cmsUrl + DEFAULT_QUERY, { query }, { headers });
    } catch (err) {
      if (err.response) {
        response = err.response;
      }
      throw err;
    } finally {
      const versionHeader = response.headers['x-powered-by'];
      if (versionHeader) {
        const version = versionHeader.replace('Strapi v', '');
        if (version !== this.strapiVersion) {
          this.setStrapiVersion(version);
        }
      }
    }

    if (response) {
      return response.data;
    } else {
      return null;
    }
  }

  formatStrapi4GraphEntity(obj) {
    const result = {
      id: obj.id
    };
    for (const attr in obj.attributes) {
      result[attr] = this.formatStrapi4GraphResponse(obj.attributes[attr]);
    }
    return result;
  }
  formatStrapi4GraphResponse(obj) {
    if (obj && obj.data && Array.isArray(obj.data) && (obj.data[0].id || obj.data[0].attributes)) {
      return obj.data.map(child => this.formatStrapi4GraphEntity(child));
    } else if (obj && obj.data && (obj.data.id || obj.data.attributes)) {
      return this.formatStrapi4GraphEntity(obj.data);
    } else {
      return obj;
    }
  }

  /**
   * Check if we are accessing Strapi v4 or above.
   * Will synchronously wait until we got version from HEAD request in init
   * @returns {Promise<boolean>}
   */
  async isVersion4() {
    // Make sure we actually have an accurate Strapi version before checking
    if (this.strapiVersion === '1.0.0') {
      // still the default. Waiting for request to finish
      await new Promise((resolve, reject) => {
        // loop until we have a version
        let count = 0;
        const wait = setInterval(() => {
          // console.log(count);
          if (this.strapiVersion !== '1.0.0') {
            clearInterval(wait);
            resolve();
          } else if (++count > 100) {
            // exit if waiting more than 10 seconds (100ms * 100 intervals = 10,000ms)
            reject();
          }
        }, 100);
      });
    }

    const val = this.strapiVersion.localeCompare('4.0.0', undefined, {
      numeric: true
    });
    return val === 1;
  }
}
export default ApiService;
