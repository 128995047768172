import React, { useContext, useEffect } from 'react';
import { Navbar, Dropdown } from 'react-bootstrap';
import { faEllipsisV, faArrowLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo } from '@fortawesome/free-solid-svg-icons';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import logo from '../logo-trans.png';
import { useTranslation } from 'react-i18next';
import { ProgressContext } from '../context/Progress';
import { getAnalytics, setUserId } from 'firebase/analytics';
import LanguageSelect from './LanguageSelect';

function MainNavbar() {
  const location = useLocation();
  const navigate = useNavigate();
  const { lang } = useParams();
  const [cookies, setCookie] = useCookies(['language']);
  const { t, i18n } = useTranslation();
  const analytics = getAnalytics();
  const user = JSON.parse(localStorage.getItem('userInfo'));
  const progress = useContext(ProgressContext);
  const direction = document.getElementsByTagName('html')[0].dir;
  const arrowDir = direction === 'rtl' ? faArrowRight : faArrowLeft;
  const toggleDir = direction === 'rtl' ? 'right' : 'left';
  const language = cookies.language || lang;
  if (language && language !== cookies.language) {
    setCookie('language', lang, { path: '/' });
  }

  // When cookie language changes, set html attributes on root <html> element
  // todo: can we move this somewhere it makes more sense? App.js? i18n.js?
  useEffect(() => {
    const htmlTag = document.getElementsByTagName('html')[0];
    htmlTag.setAttribute('dir', i18n.dir(language));
    htmlTag.setAttribute('lang', language ? language : 'ar');
    i18n.changeLanguage(language);
  }, [cookies.language, lang, language, setCookie, i18n]);

  function onClickLoginLogout() {
    if (user) {
      localStorage.removeItem('userInfo');
      localStorage.removeItem('userAnswers');
      setUserId(analytics, null);
    }
    navigate(`/${language}/signin`);
  }
  function resetProgress() {
    progress.reset();
    navigate(0);
  }

  const brand = location.pathname.match(/^\/[a-z]{0,2}\/?$/) ? (
    <img alt="" src={logo} width="40" height="40" className="d-inline-block align-top" />
  ) : (
    <button className="back-icon" onClick={() => navigate(`/${language}`, { relative: false })}>
      <FontAwesomeIcon icon={arrowDir} />
    </button>
  );

  return (
    <>
      <Navbar className="justify-content-between custom-nav" collapseOnSelect sticky="top" expand="lg">
        <Navbar.Brand className="navbar-title flex-grow-1">
          {brand}
          {t('core.progressingTogether')}
        </Navbar.Brand>

        <LanguageSelect drop={toggleDir} variant="primary" />

        <Dropdown drop={toggleDir}>
          <Dropdown.Toggle className="menu-toggle" variant="success" id="dropdown-basic">
            <FontAwesomeIcon icon={faEllipsisV} />
          </Dropdown.Toggle>
          <Dropdown.Menu className="dropdown-submenu">
            <Dropdown.Item onClick={() => resetProgress()}>
              <FontAwesomeIcon className="icon-start" icon={faRedo} />
              <h6>{t('core.resetProgress')}</h6>
            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Item onClick={onClickLoginLogout}>
              <div className="icon-start">&nbsp;</div>
              {user ? <h6>{t('auth.signOut')}</h6> : <h6>{t('auth.signIn')}</h6>}
              &nbsp;
            </Dropdown.Item>
            <Dropdown.Header>
              <div className="icon-start">&nbsp;</div>
              <div>Version {process.env.REACT_APP_VERSION}</div>
            </Dropdown.Header>
          </Dropdown.Menu>
        </Dropdown>
      </Navbar>
    </>
  );
}
export default MainNavbar;
