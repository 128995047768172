import React from 'react';
import LessonBlock from './index';

export default function SectionBlocks({ blocks, lessonId, answers }) {
  let questionCounter = 0;
  return (
    <>
      {blocks &&
        blocks.map(block => {
          if (block.type === 'questions') {
            questionCounter++;
          }
          return (
            <LessonBlock
              block={block}
              questionCounter={questionCounter}
              key={block.id}
              lessonId={lessonId}
              answers={answers}
            />
          );
        })}
    </>
  );
}
