import React, { useState, useEffect, useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useParams } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList, faBookOpen, faShareAlt, faHistory, faCommentAlt } from '@fortawesome/free-solid-svg-icons';

import { ApiContext } from '../context/Api';
import useLangParam from '../hooks/useLangParam';
import ActivitiesIcon from './icons/Activities.js';
import ApplicationIcon from './icons/Application.js';
import SectionBlocks from './lessonBlocks/SectionBlocks';
import SectionHeading from './lessonBlocks/SectionHeading';
import MainNavbar from './Navbar';
import Loader from './Loader';
import Done from './Done';
import NotFound from './NotFound';
import NetworkError from './NetworkError';

const USER_INFO = 'userInfo';

function Lesson() {
  useLangParam();
  const { t } = useTranslation();
  const { apiService } = useContext(ApiContext);
  const [currentLang] = useCookies(['language']);
  let { lessonSlug } = useParams();
  const [data, setData] = useState([]);
  const [blocksData, setBlocksData] = useState({});
  const [answers, setAnswers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [dataError, setDataError] = useState(false);
  let id = lessonSlug.substring(0, lessonSlug.indexOf('-'));

  const fetchData = useCallback(() => {
    setIsLoading(true);
    try {
      apiService
        .getLesson(id)
        .then(responseData => {
          setData(responseData.data.lesson);
          setIsLoading(false);
          setDataError(false);
          if (responseData && responseData.data && responseData.data.lesson) {
            const blocks = responseData.data.lesson.content || responseData.data.lesson.blocks;
            setBlocksData(
              blocks &&
                blocks.reduce(function(result, block) {
                  let type = block.type;
                  if (type === 'questions') {
                    type = 'scripture'; // combine questions into the scripture section
                  }
                  if (!result[type]) {
                    result[type] = [];
                  }
                  result[type].push(block);
                  return result;
                }, {})
            );
            setAnswers(responseData.data.lesson.answers);
          }
        })
        .catch(err => {
          console.error(err);
          setDataError(true);
          setIsLoading(false);
        });

      const user = JSON.parse(localStorage.getItem(USER_INFO));
      if (user && user.user.id) {
        apiService
          .getUserAnswers(user)
          .then(responseData => {
            if (responseData && responseData.data && responseData.data.answers) {
              setAnswers(responseData.data.answers);
            }
          })
          .catch(err => {
            console.error(err);
          });
      }
    } catch (err) {
      setError('Something went wrong');
      setIsLoading(false);
    }
  }, [apiService, id]);

  const onRefresh = useCallback(() => {
    setIsLoading(true);
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, currentLang]);

  if (error) {
    return error;
  }

  if (dataError) {
    return (
      <>
        <MainNavbar />
        <NetworkError onRefresh={onRefresh} />
      </>
    );
  }
  return (
    <>
      <MainNavbar />
      <Link type="button" to="../../contact" className="btn contact-btn btn-primary btn-circle btn-lg">
        <FontAwesomeIcon icon={faCommentAlt} />
      </Link>
      {!isLoading ? (
        !data ? (
          <NotFound />
        ) : (
          <Container>
            <div key={data.id} className="lesson-title-box">
              <h1 className="lesson-title">{data.name}</h1>
              <p className="lesson-subtitle">{data.subtitle}</p>
            </div>
            <div className="lesson-section">
              <SectionHeading
                icon={<FontAwesomeIcon icon={faList} />}
                title={t('lesson.lessonObjectives')}
                blocks={blocksData.objectives}
              />
              <SectionBlocks blocks={blocksData.objectives} />
            </div>

            <div className="lesson-section">
              <SectionHeading
                icon={<FontAwesomeIcon icon={faHistory} />}
                title={t('lesson.recap')}
                blocks={blocksData.recap}
              />
              <SectionBlocks blocks={blocksData.recap} />
            </div>

            <div className="lesson-section">
              <SectionHeading
                icon={<FontAwesomeIcon icon={faBookOpen} />}
                title={t('lesson.scriptureReading')}
                blocks={blocksData.scripture}
              />
              <SectionBlocks blocks={blocksData.scripture} lessonId={id} answers={answers} />
            </div>

            <div className="lesson-section">
              <SectionHeading
                icon={<ActivitiesIcon className="icon-svg" />}
                title={t('lesson.optionalActivities')}
                blocks={blocksData.activities}
              />

              <SectionBlocks blocks={blocksData.activities} />
            </div>

            <div className="lesson-section">
              <SectionHeading
                icon={<ApplicationIcon className="icon-svg" />}
                title={t('lesson.applications')}
                blocks={blocksData.application}
              />

              <SectionBlocks blocks={blocksData.application} lessonId={id} />
            </div>

            <div className="lesson-section">
              <SectionHeading
                icon={<FontAwesomeIcon icon={faShareAlt} />}
                title={t('lesson.sharing')}
                blocks={blocksData.sharing}
              />

              <SectionBlocks blocks={blocksData.sharing} lessonId={id} />
            </div>

            <Done data={data} />
          </Container>
        )
      ) : (
        <Loader />
      )}
    </>
  );
}
export default Lesson;
